.category-section {
    padding-top: 60px;
    @media #{$tablet-device} {
        padding-top:40px;
    }
    @media #{$large-mobile} {
        padding-top:20px;
    }
}

.single-category-item {
    text-align: center;
    margin-top: 40px;
    .content {
        margin-top: 25px;
        .title {
            font-weight: 600;
            line-height: 1.125;
            margin: 0 0 10px;
        }
        .number {
            font-size: 16px;
            font-weight: 400;
            display: block;
            transition: all .5s ease 0s;
            color: #999;
        }
    }
    &:hover {
        .category-item-thum {
            img {
                transform: scale(1.1);
            }
        }
    }
}
.category-item-thum {
    max-height: 240px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    img {
        border-radius: 100%;
        width: 100%;
        transform: scale(1.0);
    }
}