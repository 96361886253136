
/*======================================
=        03. Offcanvas Minicart Area   =
=======================================*/
.offcanvas-minicart_wrapper {
	.offcanvas-menu-inner {
		padding: 60px;
		width: 450px;
		box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
		background-color: $white;
		@media #{$laptop-device}{
			width: 100%;
			padding: 53px 50px 45px;
		}
		.close-btn-box {
			text-align: left;
			margin-bottom: 30px;
		}
		.btn-close {
			background: transparent;
			color: #333;
			top: 0;
			right: 0;
			left: auto;
			text-align: right;
			font-size: 20px;
			&:hover{
				color: #212121;
			}
		}
		.minicart-content {
			.minicart-list {
				max-height: 311px;
				position: relative;
				overflow: auto;
				> li {
					padding-bottom: 30px;
					&:last-child {
						padding-bottom: 0;
					}
					&.minicart-product {
						display: flex;
						border-bottom: 1px solid #ddd;
						margin-bottom: 20px;
						> a {
							&.product-item_remove {
								> i{
									position: absolute;
									font-size: 14px;
									top: auto;
									right: 15px;
								}
							}
						}
						.product-item_content {
							flex-basis: calc(100% - 70px);
							max-width: calc(100% - 70px);
							padding-left: 20px;
							padding-right: 10px;
							a {
								&.product-item_title {
									color: $black;
									line-height: 1.4;
									font-size: 15px;
									font-weight: 500;
									margin-bottom: 5px;
									width: 90%;
									display: block;
									&:hover{
										color: #333;
									}
								}
							}
							label {
								font-weight: 500;
								display: block;
								margin-bottom: 0;
								margin-top: 0;
								font-size: 15px;
								span {
									color: #666;
									padding-left: 10px;
								}
							}
							.product-item_quantity {
								display: block;
								font-size: 13px;
								color: #333;
							}
						}
					}
				}
			}
		}
		.minicart-item_total {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 0;
			font-size: 24px;
			font-weight: 500;
		}
		.minicart-btn_area {
			padding-bottom: 15px;
			.hiraola-btn {
				height: 60px;
				line-height: 60px;
				color: $white !important;
			}
		}
	}
}


.global-overlay.overlay-open {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: rgba(0, 0, 0, 0.7);
	z-index: 999;
}

/* ---Offcanvas--- */
.offcanvas-minicart_wrapper {
	.offcanvas-menu-inner {
		background-color: #fff;
		position: fixed;
		top: 0;
		right: -440px;
		width: 440px;
		height: 100%;
		z-index: 9999;
		visibility: hidden;
		overflow-y: auto;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		.offcanvas-inner_logo{
			padding: 30px 0;
			text-align: center;
		}
		@media #{$large-mobile}{
			width: 300px;
			padding: 60px 20px 0 !important;
		}
	}
	&.open {
		.offcanvas-menu-inner {
			visibility: visible;
			left: auto;
			right: 0;
			opacity: 1;
			z-index: 9999;
		}
	}
}

